<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Pengeluaran Kas/Bank</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Keuangan / Transaksi / Pengeluaran</span>
      </div>
    </div>
    <div class="card bg-white">
      <div class="flex justify-content-end mb-2">
        <Button
          icon="pi pi-plus"
          label="Pengeluaran"
          @click="$router.push({ name: 'CashOutAdd' })"
        />
      </div>
      <grid-pengeluaran
        :items="items"
        :accounts="accounts"
        :loading="isLoading"
        :total="totalRecords"
        :grid-options="options"
        @request="onRequestData"
        @print="onPrintBukti"
        @edit="onEditData"
        @delete="onConfirmDeletion"
      />
    </div>
    <Dialog
      header="Hapus Pengeluaran"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >Pengeluaran <strong>{{ form.kk_no }}</strong> akan dihapus. Proses
          ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="onCloseFormDelete"
          class="p-button-text mr-2"
        />
        <Button
          label="Hapus"
          icon="pi pi-trash"
          @click="onDeleteData"
          class="p-button-text p-button-danger"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import AccountService from '@/services/AccountService'
import DaftarKasService from '@/services/DaftarKasService'
import GridPengeluaran from '@/components/keuangan/GridPengeluaran'
import errorHandler from '@/helpers/error-handler'
import Hotkey from '@/components/Hotkey'

export default {
  components: {
    GridPengeluaran,
    Hotkey,
  },
  data() {
    return {
      dataService: null,
      dialogHapus: false,
      isLoading: false,
      items: [],
      accounts: [],
      form: {},
      totalRecords: 0,
      options: {
        page: 1,
        rows: 10,
        sortField: '',
        sortOrder: -1,
        filters: null,
      },
    }
  },
  created() {
    this.dataService = new AccountService()
    this.dataKasService = new DaftarKasService()
  },
  mounted() {
    this.loadGridData()
    this.onLoadDaftarKas()
  },
  methods: {
    onLoadDaftarKas() {
      this.dataKasService
        .get('?sort=nama')
        .then((res) => {
          this.accounts = res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Daftar Kas', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onRequestData(e) {
      this.options = e
      this.loadGridData()
    },
    loadGridData() {
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters

      this.isLoading = true
      this.dataService
        .paginatePengeluaran(page, perPage, sortField, sortOrder, filters)
        .then((res) => {
          this.items = res.data.data
          this.totalRecords = res.data.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data Pengeluaran', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onPrintBukti(data) {
      this.dataService
        .printPengeluaran(data.id)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'pengeluaran.pdf')
          document.body.appendChild(link)
          link.click()
        })
        .catch((err) => {
          errorHandler(err, 'Print Pengeluaran', this)
        })
    },
    onEditData(data) {
      this.$router.push({ name: 'CashOutEdit', params: { id: data.id } })
    },
    onConfirmDeletion(data) {
      this.form = data
      this.dialogHapus = true
    },
    onCloseFormDelete() {
      this.dialogHapus = false
    },
    onDeleteData() {
      this.dataService
        .deletePengeluaran(this.form.id)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Pengeluaran',
              detail: 'Data berhasil dihapus.',
              life: 3000,
            })
            this.loadGridData()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Pengeluaran', this)
        })
      this.form = {}
      this.dialogHapus = false
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        this.$router.push({ name: 'CashOutAdd' })
      }
    },
  },
}
</script>
